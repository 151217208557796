import React from "react";
import { createRoot, type Root } from "react-dom/client";
import { Theme } from "@emotion/react";

import { initTranslations } from "@Utils";
import { ApplicationWrapper } from "@Components/ApplicationWrapper";
import type { IClientConfiguration, IClientConfigurationOverrides, IEvent } from "./client.types";

/**
 * @param getConfiguration The client's getConfiguration function
 * @param theme The client's theme
 * @returns
 * An object including a mount function and a rerender function. The mount function is used to mount the widget to the DOM.
 * The rerender function is only intended for development use (hot reload) and used to rerender the widget with modified configuration and/or theme.
 */

export const generateMountFunctions = (
    getConfiguration: (configurationOverrides?: IClientConfigurationOverrides) => IClientConfiguration,
    theme: Theme,
) => {
    let root: Root | null = null;

    return {
        rerender: (
            modifiedGetConfiguration: (configurationOverrides?: IClientConfigurationOverrides) => IClientConfiguration,
            modifiedTheme: Theme,
        ) => {
            if (root) {
                root.render(React.createElement(ApplicationWrapper, { configuration: modifiedGetConfiguration(), theme: modifiedTheme }));
            }
        },
        mount: (elementSelector: string, configurationOverrides?: IClientConfigurationOverrides) => {
            if (!elementSelector) return;

            const element = document.querySelector(elementSelector);
            const configuration = getConfiguration(configurationOverrides);

            initTranslations({
                customTranslations: configuration.translations,
            });

            const loadedEvent: IEvent = {
                category: "widget",
                action: "loaded",
                label: "Widget loaded",
                data: true,
            };

            try {
                if (!element) {
                    throw new Error(`No element found using selector "${elementSelector}"`);
                }

                if (!root) {
                    root = createRoot(element, { identifierPrefix: "econans-borrowing-space-" });
                }

                root.render(React.createElement(ApplicationWrapper, { configuration, theme }));
            } catch {
                loadedEvent.data = false;
            }

            if (typeof configuration.onEvent === "function") {
                configuration.onEvent(loadedEvent);
            }
        },
    };
};

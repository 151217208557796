import { BorrowingSpaceCalc, type BorrowingSpaceModels } from "@econans/calculations";
import { AdditionalHousingType, CustomerScenario, PrivateLoanType } from "@Utils";
import type { IBorrowerValues, IChildValues, IHousingValues, IPrivateLoanValues } from "@Components";

const MONTHS_IN_YEAR = 12;

export function getAdditionalHousingCosts(
    additionalHousings: Array<IHousingValues> | undefined,
    interestRate: number,
    amortizationRate: number,
    calculateAdditionalHousingMaintenanceCost?: (housingType: AdditionalHousingType) => number,
): BorrowingSpaceModels.IAdditionalHousing {
    const mortgage: Array<BorrowingSpaceModels.ILoanInput> = [];
    let fee = 0;
    let maintenance = 0;
    additionalHousings?.forEach(({ housingFee, housingMortgage, additionalHousingType }) => {
        const mortgageAmount = housingMortgage || 0;
        mortgage.push({
            amount: mortgageAmount,
            interestRate,
            monthlyAmortization: (mortgageAmount * amortizationRate) / MONTHS_IN_YEAR,
        });

        fee += housingFee || 0;
        maintenance += calculateAdditionalHousingMaintenanceCost ? calculateAdditionalHousingMaintenanceCost(additionalHousingType) : 0;
    });

    return { mortgage, fee, maintenance };
}

export function getHousingValueAndMortgageAmount(
    customerScenario: CustomerScenario | undefined,
    downPayment: number | undefined,
    prospectValuation: number | undefined,
    minDownPaymentRatio: number,
): [number, number] {
    let housingValue = 0;
    let mortgageAmount = 0;
    if (customerScenario === CustomerScenario.BUY_NEW_HOUSING) {
        housingValue = downPayment ? downPayment / minDownPaymentRatio : 0;
        mortgageAmount = downPayment ? housingValue - downPayment : 0;
    } else if (customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE) {
        housingValue = prospectValuation || 0;
        mortgageAmount = housingValue - housingValue * minDownPaymentRatio;
    }

    return [housingValue, mortgageAmount];
}

export function getMortgage(mortgageAmount: number, interestRate: number, amortizationRate: number): Array<BorrowingSpaceModels.ILoanInput> {
    const monthlyAmortization = (mortgageAmount * amortizationRate) / MONTHS_IN_YEAR;
    return [
        {
            amount: mortgageAmount,
            interestRate,
            monthlyAmortization,
        },
    ];
}

export function getAdults(borrowers: Array<IBorrowerValues> | undefined): Array<BorrowingSpaceModels.IAdult> {
    const adults: Array<BorrowingSpaceModels.IAdult> = [];
    borrowers?.forEach((borrower) => {
        adults.push({
            age: 30,
            income: borrower.borrowerIncome,
            employmentRate: 1,
        });
    });
    return adults;
}

export function getChildren(inputChildren: Array<IChildValues> | undefined): Array<BorrowingSpaceModels.IChild> {
    const children: Array<BorrowingSpaceModels.IChild> = [];
    inputChildren?.forEach((child) => {
        children.push({
            age: 10,
            householdRate: child.childHouseholdRate,
        });
    });

    return children;
}

function mapPrivateLoanType(privateLoanType: PrivateLoanType): BorrowingSpaceCalc.PrivateLoanType {
    switch (privateLoanType) {
        case PrivateLoanType.ACCOUNT_CREDIT:
            return BorrowingSpaceCalc.PrivateLoanType.ACCOUNT_CREDIT;
        case PrivateLoanType.INSTALMENT_CREDIT:
            return BorrowingSpaceCalc.PrivateLoanType.INSTALMENT_CREDIT;
        case PrivateLoanType.STUDENT_LOAN:
            return BorrowingSpaceCalc.PrivateLoanType.STUDENT_LOAN;
        case PrivateLoanType.UNSECURED_LOAN:
            return BorrowingSpaceCalc.PrivateLoanType.UNSECURED_LOAN;
        default:
            throw new Error(`Unknown private loan type: ${privateLoanType}`);
    }
}

export function getPrivateLoans(
    privateLoansInput: Array<IPrivateLoanValues> | undefined,
    accountCreditPaidOffYears: number,
    accountCreditInterest: number,
    instalmentCreditPaidOffYears: number,
    instalmentCreditInterest: number,
    unsecuredLoanPaidOffYears: number,
    unsecuredLoanInterest: number,
): Array<BorrowingSpaceModels.IPrivateLoan> {
    const privateLoans: Array<BorrowingSpaceModels.IPrivateLoan> = [];
    privateLoansInput?.forEach((loan) => {
        if (loan.privateLoanType === PrivateLoanType.CHOOSE) {
            return;
        }

        const type = mapPrivateLoanType(loan.privateLoanType);
        let amount = 0;
        let amortization = 0;
        let interestRate = 0;

        switch (loan.privateLoanType) {
            case PrivateLoanType.STUDENT_LOAN:
                amount = loan.privateLoanValue * MONTHS_IN_YEAR;
                amortization = loan.privateLoanValue;
                interestRate = 0; // The student loan interest is included in amortization
                break;
            case PrivateLoanType.ACCOUNT_CREDIT:
                amount = loan.privateLoanValue;
                amortization = accountCreditPaidOffYears > 0 ? loan.privateLoanValue / accountCreditPaidOffYears / MONTHS_IN_YEAR : 0;
                interestRate = accountCreditInterest;
                break;
            case PrivateLoanType.INSTALMENT_CREDIT:
                amount = loan.privateLoanValue;
                amortization = instalmentCreditPaidOffYears > 0 ? loan.privateLoanValue / instalmentCreditPaidOffYears / MONTHS_IN_YEAR : 0;
                interestRate = instalmentCreditInterest;
                break;
            case PrivateLoanType.UNSECURED_LOAN:
                amount = loan.privateLoanValue;
                amortization = unsecuredLoanPaidOffYears > 0 ? loan.privateLoanValue / unsecuredLoanPaidOffYears / MONTHS_IN_YEAR : 0;
                interestRate = unsecuredLoanInterest;
                break;
            default:
                break;
        }

        privateLoans.push({ type, amount, amortization, interestRate });
    });

    return privateLoans;
}

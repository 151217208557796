import React from "react";
import { useTranslation } from "react-i18next";
import { Label, IntegerInput } from "@econans/ui-components";
import type { IBorrowerValues, IFormValues } from "@Components";
import { TranslationNamespaceEnum, useConfiguration } from "@Utils";
import { sharedStyles } from "../sharedStyles";

interface IBorrowerProps {
    index: number;
    borrowerValues: IBorrowerValues;
    handleArrayChange(property: keyof IFormValues, newValue: IBorrowerValues, index: number): void;
}

export const Borrower: React.FC<IBorrowerProps> = ({ index, borrowerValues, handleArrayChange }) => {
    const { clearOnFocusIfZero } = useConfiguration();
    const { borrowerIncome: income } = borrowerValues;
    const { t } = useTranslation([TranslationNamespaceEnum.FORM, TranslationNamespaceEnum.SUFFIXES]);

    return (
        <React.Fragment>
            <Label css={sharedStyles.label} htmlFor={`econans-borrowing-space-borrower-income-${index}`}>
                {t("borrower-income-label", { number: index + 1 })}
            </Label>
            <IntegerInput
                id={`econans-borrowing-space-borrower-income-${index}`}
                min={0}
                max={200000}
                step={1000}
                value={income}
                onChange={(newValue) => newValue && handleArrayChange("borrowers", { borrowerIncome: newValue }, index)}
                suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}
                clearOnFocusIfZero={clearOnFocusIfZero}
            />
        </React.Fragment>
    );
};

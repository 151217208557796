export enum CustomerScenario {
    MOVE_EXISTING_MORTGAGE = "move-existing-mortgage",
    BUY_NEW_HOUSING = "buy-new-housing",
}

export enum ProspectType {
    HOUSE = "HOUSE",
    COTTAGE = "COTTAGE",
    CONDOMINIUM = "CONDOMINIUM",
}

export enum AdditionalHousingType {
    CHOOSE = "CHOOSE",
    CONDOMINIUM = "CONDOMINIUM",
    RENTAL_APARTMENT = "RENTAL_APARTMENT",
    HOUSE = "HOUSE",
    COTTAGE = "COTTAGE",
    AGRICULTIRAL_ESTATE = "AGRICULTIRAL_ESTATE",
    OTHER = "OTHER",
}

export enum PrivateLoanType {
    CHOOSE = "CHOOSE",
    STUDENT_LOAN = "STUDENT_LOAN",
    ACCOUNT_CREDIT = "ACCOUNT_CREDIT",
    INSTALMENT_CREDIT = "INSTALMENT_CREDIT",
    UNSECURED_LOAN = "UNSECURED_LOAN",
}

export enum LanguageKeyEnum {
    SV = "sv",
    EN = "en",
    CUSTOM = "custom",
}

export enum TranslationNamespaceEnum {
    "FORM" = "form",
    "RESULT" = "result",
    "DISCLAIMER" = "disclaimer",
    "INFO" = "info",
    "SUFFIXES" = "suffixes",
    "GENERIC" = "generic",
}

export interface ITranslation {
    [key: string]: string;
}

export type TranslationResources = {
    [LanguageKey in LanguageKeyEnum]?: {
        [NamespaceKey in TranslationNamespaceEnum]?: ITranslation;
    };
};

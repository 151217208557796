import type { ICalculationParameters, IClientConfiguration, IClientConfigurationOverrides } from "@Clients/client.types";

export function getConfiguration(overrides?: IClientConfigurationOverrides): IClientConfiguration {
    const calculationParameters: ICalculationParameters = {
        listInterest: [
            {
                id: "3m",
                label: "3 mån - 1,63 %",
                listInterest: 0.0163,
                discounts: [
                    { maxLTV: 0.6, discount: 0.0048 },
                    { maxLTV: 0.7, discount: 0.0038 },
                ],
                period: "3 månader",
            },
            {
                id: "1y",
                label: "1 år - 1,54 %",
                listInterest: 0.0154,
                discounts: [
                    { maxLTV: 0.6, discount: 0.005 },
                    { maxLTV: 0.7, discount: 0.004 },
                ],
                period: "1 år",
            },
        ],
        calculationInterestRate: 0.06,
        additionalMortgageInterestRate: 0.06,
        additionalMortgageAmortizationRate: 0.02,
        accountCreditPaidOffYears: 0,
        accountCreditInterest: 0.05,
        instalmentCreditPaidOffYears: 7,
        instalmentCreditInterest: 0.07,
        unsecuredLoanPaidOffYears: 5,
        unsecuredLoanInterest: 0.1,
        minDownPaymentRatio: 0.25,
        minMortgageAllowed: 200000,
        minMortgageDiscountLimit: 0,
        maxPropertyTax: 9525,
        retirementAge: 66,
        adultsHouseholdExpense: { oneAdult: 11400, twoAdults: 18500 },
        childHouseholdExpense: { fullTime: 4100, halfTime: 2050 },
        ...overrides?.calculationParameters,
    };

    return {
        defaultFormValues: overrides?.defaultFormValues,
        calculationParameters,
        clearOnFocusIfZero: overrides?.clearOnFocusIfZero,
        hideCustomerScenarioChoice: overrides?.hideCustomerScenarioChoice,
        hideCondominiumScenarioButton: overrides?.hideCondominiumScenarioButton,
        onPrimaryCTAClick: overrides?.onPrimaryCTAClick
            ? overrides.onPrimaryCTAClick
            : () => {
                  // eslint-disable-next-line no-console
                  console.log("Hello Cypress! The primary CTA button was clicked.");
              },
        onSecondaryCTAClick: overrides?.onSecondaryCTAClick
            ? overrides.onSecondaryCTAClick
            : () => {
                  // eslint-disable-next-line no-console
                  console.log("Hello Cypress! The secondary CTA button was clicked.");
              },
        onEvent: overrides?.onEvent,
        ...(overrides?.translations ? { translations: overrides.translations } : {}),
    };
}

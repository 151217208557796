import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, useTheme } from "@emotion/react";
import {
    Heading,
    Label,
    Select,
    IntegerInput,
    Button,
    DisguisedRadioButton,
    Grid,
    Fieldset,
    Legend,
    RenderComponentInstance,
} from "@econans/ui-components";
import { BorrowingSpaceCalc } from "@econans/calculations";

import type { IBorrowerValues, IChildValues, IFormValues, IHousingValues, IPrivateLoanValues } from "@Components";
import { TranslationNamespaceEnum, CustomerScenario, ProspectType, useConfiguration } from "@Utils";
import { Child } from "../Child";
import { Borrower } from "../Borrower";
import { Housing } from "../Housing";
import { Loan } from "../Loan";
import { Section, sharedStyles } from "../sharedStyles";
import { styles } from "./Form.styles";

interface IFormProps {
    formValues: IFormValues;
    handleChange(property: keyof IFormValues, newValue: number | string): void;
    handleArrayChange(
        property: keyof IFormValues,
        newValue?: IBorrowerValues | IChildValues | IHousingValues | IPrivateLoanValues,
        index?: number,
        newSize?: number,
    ): void;
}

export const Form: React.FC<IFormProps> = ({ formValues, handleChange, handleArrayChange }) => {
    const {
        clearOnFocusIfZero,
        calculateMaintenanceCostFromHousingArea,
        hideCustomerScenarioChoice,
        hideCondominiumScenarioButton,
        calculationParameters,
    } = useConfiguration();
    const { t } = useTranslation([TranslationNamespaceEnum.FORM, TranslationNamespaceEnum.GENERIC, TranslationNamespaceEnum.SUFFIXES]);
    const theme = useTheme();

    const {
        customerScenario,
        prospectType,
        condominiumFee,
        prospectArea,
        maintenanceCost,
        municipality,
        prospectValuation,
        downPayment,
        borrowers,
        children,
        childSupportIncome,
        childSupportExpense,
        additionalHousings,
        privateLoans,
    } = formValues;

    const customerScenarioControls: Array<React.ReactNode> = useMemo(
        () =>
            Object.values(CustomerScenario).map((scenario) => (
                <DisguisedRadioButton
                    key={scenario}
                    radioButtonProps={{
                        name: "customer-scenario",
                        value: scenario,
                        onChange: (event) => handleChange("customerScenario", event.target.value),
                        checked: formValues.customerScenario === scenario,
                    }}
                    content={t(`customer-scenario-option-${scenario}`)}
                />
            )),
        [formValues.customerScenario, t, handleChange],
    );

    const municipalities = BorrowingSpaceCalc.getMunicipalities().map((m) => ({ value: m.name, label: m.name }));
    const moveMortgage = customerScenario === CustomerScenario.MOVE_EXISTING_MORTGAGE;

    return (
        <form css={styles.form} onSubmit={(event) => event.preventDefault()}>
            <Section>
                <Heading level="3">{t("housing-heading")}</Heading>
                {!hideCustomerScenarioChoice && (
                    <Fieldset css={[styles.formFieldset, css`margin-bottom: ${theme.spacing(6)};`]}>
                        <Legend css={[styles.formFieldsetLegend]}>{t("customer-scenario-label")}</Legend>
                        <Grid columns={{ mobile: 1, desktop: 2 }} gap={theme.spacing(4)}>
                            {customerScenarioControls}
                        </Grid>
                    </Fieldset>
                )}
                <Fieldset css={styles.formFieldset}>
                    <Legend css={[styles.formFieldsetLegend]}>{t("prospect-type-label")}</Legend>
                    <Grid
                        columns={theme.widget?.Form?.prospectType?.columns ?? { mobile: 1, desktop: 2 }}
                        gap={theme.widget?.Form?.prospectType?.gap ?? theme.spacing(4)}
                    >
                        <DisguisedRadioButton
                            css={styles.disguisedRadioButtonWithIcon}
                            content={t(`prospect-type-${ProspectType.HOUSE}`)}
                            icon={
                                theme.widget?.Form?.prospectType?.house?.icon?.component && (
                                    <RenderComponentInstance instance={theme.widget.Form.prospectType.house.icon.component} />
                                )
                            }
                            iconPlacement={theme.widget?.Form?.prospectType?.house?.icon?.placement ?? "beforeContent"}
                            radioButtonProps={{
                                name: "prospect-type-house",
                                value: "false",
                                onChange: () => handleChange("prospectType", ProspectType.HOUSE),
                                checked: prospectType === ProspectType.HOUSE,
                            }}
                        />
                        {!hideCondominiumScenarioButton && (
                            <DisguisedRadioButton
                                css={styles.disguisedRadioButtonWithIcon}
                                content={t(`prospect-type-${ProspectType.CONDOMINIUM}`)}
                                icon={
                                    theme.widget?.Form?.prospectType?.condominium?.icon?.component && (
                                        <RenderComponentInstance instance={theme.widget.Form.prospectType.condominium.icon.component} />
                                    )
                                }
                                iconPlacement={theme.widget?.Form?.prospectType?.condominium?.icon?.placement ?? "beforeContent"}
                                radioButtonProps={{
                                    name: "prospect-type-condominium",
                                    value: "false",
                                    onChange: () => handleChange("prospectType", ProspectType.CONDOMINIUM),
                                    checked: prospectType === ProspectType.CONDOMINIUM,
                                }}
                            />
                        )}
                        <DisguisedRadioButton
                            css={styles.disguisedRadioButtonWithIcon}
                            content={t(`prospect-type-${ProspectType.COTTAGE}`)}
                            icon={
                                theme.widget?.Form?.prospectType?.cottage?.icon?.component && (
                                    <RenderComponentInstance instance={theme.widget.Form.prospectType.cottage.icon.component} />
                                )
                            }
                            iconPlacement={theme.widget?.Form?.prospectType?.cottage?.icon?.placement ?? "beforeContent"}
                            radioButtonProps={{
                                name: "prospect-type-cottage",
                                value: "false",
                                onChange: () => handleChange("prospectType", ProspectType.COTTAGE),
                                checked: prospectType === ProspectType.COTTAGE,
                            }}
                        />
                    </Grid>
                </Fieldset>
                {prospectType === ProspectType.CONDOMINIUM && (
                    <React.Fragment>
                        <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-monthly-fee">
                            {t("monthly-fee-label")}
                        </Label>
                        <IntegerInput
                            id="econans-borrowing-space-monthly-fee"
                            min={0}
                            max={40000}
                            step={100}
                            value={condominiumFee}
                            onChange={(newValue) => handleChange("condominiumFee", newValue)}
                            suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}
                            clearOnFocusIfZero={clearOnFocusIfZero}
                        />
                    </React.Fragment>
                )}
                {prospectType === ProspectType.HOUSE && calculateMaintenanceCostFromHousingArea && (
                    <React.Fragment>
                        <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-prospect-area">
                            {t("prospect-area-label")}
                        </Label>
                        <IntegerInput
                            id="econans-borrowing-space-prospect-area"
                            min={0}
                            max={1000}
                            step={100}
                            value={prospectArea}
                            onChange={(newValue) => handleChange("prospectArea", newValue)}
                            suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:squareMeters`)}
                            clearOnFocusIfZero={clearOnFocusIfZero}
                        />
                    </React.Fragment>
                )}
                {!calculateMaintenanceCostFromHousingArea && (
                    <React.Fragment>
                        <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-maintenance-cost">
                            {t("maintenance-cost-label")}
                        </Label>
                        <IntegerInput
                            id="econans-borrowing-space-maintenance-cost"
                            min={0}
                            max={20000}
                            step={100}
                            value={maintenanceCost}
                            onChange={(newValue) => handleChange("maintenanceCost", newValue)}
                            suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}
                            clearOnFocusIfZero={clearOnFocusIfZero}
                        />
                    </React.Fragment>
                )}
                <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-municipality">
                    {t("municipality-label")}
                </Label>
                <Select
                    id="econans-borrowing-space-municipality"
                    options={[
                        {
                            label: `${t("municipality-choose")}`,
                            value: "",
                            disabled: true,
                        },
                        ...municipalities,
                    ]}
                    value={municipality}
                    onChange={(event) => handleChange("municipality", event.target.value)}
                />
            </Section>

            {/* Lån */}
            <Section>
                <Heading level="3" noBottomMargin>
                    {t(`loan-heading`)}
                </Heading>
                <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-loan">
                    {t(moveMortgage ? "housing-valuation-label" : "downPayment-label")}
                </Label>
                <IntegerInput
                    id="econans-borrowing-space-loan"
                    value={(moveMortgage ? prospectValuation : downPayment) ?? 0}
                    min={0}
                    max={moveMortgage ? 100000000 : 20000000}
                    step={100000}
                    onChange={(newValue) => handleChange(moveMortgage ? "prospectValuation" : "downPayment", newValue)}
                    suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sek`)}
                    clearOnFocusIfZero={clearOnFocusIfZero}
                />
            </Section>

            {/* Låntagre */}
            <Section>
                <Heading level="3">{t("borrower-label")}</Heading>
                <Fieldset css={styles.formFieldset}>
                    <Legend css={[styles.formFieldsetLegend, sharedStyles.visuallyHidden]}>{t("borrower-label")}</Legend>
                    <Grid columns={{ mobile: 1, desktop: 2 }} gap={theme.spacing(4)}>
                        <DisguisedRadioButton
                            content={t("borrower-one")}
                            radioButtonProps={{
                                name: "borrowers",
                                value: 1,
                                onChange: () => handleArrayChange("borrowers", undefined, undefined, 1),
                                checked: borrowers?.length === 1,
                            }}
                        />
                        <DisguisedRadioButton
                            content={t("borrower-two")}
                            radioButtonProps={{
                                name: "borrowers",
                                value: 2,
                                onChange: () => handleArrayChange("borrowers", undefined, undefined, 2),
                                checked: borrowers?.length === 2,
                            }}
                        />
                    </Grid>
                </Fieldset>

                {borrowers &&
                    borrowers?.map(({ borrowerIncome }, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Borrower key={`borrower-${index}`} index={index} borrowerValues={{ borrowerIncome }} handleArrayChange={handleArrayChange} />
                    ))}
            </Section>

            {/* Barn i hushållet */}
            <Section>
                <Heading level="3" noBottomMargin>
                    {t("children-heading")}
                </Heading>
                <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-no-of-children">
                    {t("children-number-label", {
                        maxChildAge:
                            (calculationParameters.maxChildAge ??
                                BorrowingSpaceCalc.Forecast.getDefaultLocalizationParameters().household.maxChildAge) + 1,
                    })}
                </Label>
                <IntegerInput
                    id="econans-borrowing-space-no-of-children"
                    css={styles.formChildNumberInput}
                    min={0}
                    max={6}
                    step={1}
                    value={children?.length ?? 0}
                    displayStepperButtons
                    onChange={(newValue) => handleArrayChange("children", undefined, undefined, newValue > 6 ? 6 : newValue)}
                    debounceTime={0}
                    clearOnFocusIfZero={clearOnFocusIfZero}
                />

                {children && children.length > 0 && (
                    <React.Fragment>
                        {children?.map(({ childHouseholdRate }, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Child key={`child-${index}`} index={index} childValues={{ childHouseholdRate }} handleArrayChange={handleArrayChange} />
                        ))}
                        <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-child-support-income">
                            {t("incoming-child-support")}
                        </Label>
                        <IntegerInput
                            id="econans-borrowing-space-child-support-income"
                            min={0}
                            max={20000}
                            step={1000}
                            value={childSupportIncome}
                            onChange={(newValue) => handleChange("childSupportIncome", newValue)}
                            suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}
                            clearOnFocusIfZero={clearOnFocusIfZero}
                        />
                    </React.Fragment>
                )}

                <Label css={sharedStyles.label} htmlFor="econans-borrowing-space-child-support-expense">
                    {t("outgoing-child-support")}
                </Label>
                <IntegerInput
                    id="econans-borrowing-space-child-support-expense"
                    min={0}
                    max={20000}
                    step={1000}
                    value={childSupportExpense}
                    onChange={(newValue) => handleChange("childSupportExpense", newValue)}
                    suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}
                    clearOnFocusIfZero={clearOnFocusIfZero}
                />
            </Section>

            {/* Har du bostäder som ska behållas?  */}
            <Section>
                <Heading level="3">{t("additional-housing-heading")}</Heading>
                <Fieldset css={styles.formFieldset}>
                    <Legend css={[styles.formFieldsetLegend, sharedStyles.visuallyHidden]}>{t("additional-housing-heading")}</Legend>
                    <Grid css={styles.grid}>
                        <DisguisedRadioButton
                            css={sharedStyles.smallButton}
                            content={t(`${TranslationNamespaceEnum.GENERIC}:yes`)}
                            radioButtonProps={{
                                name: "additional-housing",
                                value: "true",
                                onChange: () => handleArrayChange("additionalHousings", undefined, undefined, 1),
                                checked: additionalHousings !== undefined && additionalHousings.length > 0,
                            }}
                        />
                        <DisguisedRadioButton
                            css={sharedStyles.smallButton}
                            content={t(`${TranslationNamespaceEnum.GENERIC}:no`)}
                            radioButtonProps={{
                                name: "additional-housing",
                                value: "false",
                                onChange: () => handleArrayChange("additionalHousings", undefined, undefined, 0),
                                checked: additionalHousings?.length === undefined || additionalHousings?.length === 0,
                            }}
                        />
                    </Grid>
                </Fieldset>

                {additionalHousings && additionalHousings.length > 0 && (
                    <React.Fragment>
                        {additionalHousings.map(({ additionalHousingType, housingFee, housingMortgage }, index) => (
                            <Housing
                                // eslint-disable-next-line react/no-array-index-key
                                key={`additional-housing-${index}`}
                                index={index}
                                housingValues={{ additionalHousingType, housingFee, housingMortgage }}
                                handleArrayChange={handleArrayChange}
                            />
                        ))}
                        <Button
                            css={[sharedStyles.removeButton]}
                            variant="TEXT"
                            icon={
                                theme.widget?.Form?.additionalHousings?.buttons?.remove?.icon && (
                                    <RenderComponentInstance instance={theme.widget?.Form?.additionalHousings?.buttons?.remove?.icon} />
                                )
                            }
                            iconPlacement="left"
                            onClick={() => handleArrayChange("additionalHousings", undefined, undefined, additionalHousings.length - 1)}
                        >
                            {t("additional-housing-remove")}
                        </Button>
                        <Button
                            css={styles.formAddButton}
                            variant="TEXT"
                            icon={
                                theme.widget?.Form?.additionalHousings?.buttons?.add?.icon && (
                                    <RenderComponentInstance instance={theme.widget?.Form?.additionalHousings?.buttons?.add?.icon} />
                                )
                            }
                            iconPlacement="left"
                            onClick={() => {
                                handleArrayChange("additionalHousings", undefined, undefined, additionalHousings.length + 1);
                            }}
                        >
                            {t("additional-housing-add")}
                        </Button>
                    </React.Fragment>
                )}
            </Section>

            {/* Har du några övriga lån som ska behållas? */}
            <Section>
                <Heading level="3">{t("private-loan-heading")}</Heading>
                <Fieldset css={styles.formFieldset}>
                    <Legend css={[styles.formFieldsetLegend, sharedStyles.visuallyHidden]}>{t("private-loan-heading")}</Legend>
                    <Grid css={styles.grid}>
                        <DisguisedRadioButton
                            css={sharedStyles.smallButton}
                            content={t(`${TranslationNamespaceEnum.GENERIC}:yes`)}
                            radioButtonProps={{
                                name: "private-loan",
                                value: "true",
                                onChange: () => handleArrayChange("privateLoans", undefined, undefined, 1),
                                checked: privateLoans !== undefined && privateLoans.length > 0,
                            }}
                        />
                        <DisguisedRadioButton
                            css={sharedStyles.smallButton}
                            content={t(`${TranslationNamespaceEnum.GENERIC}:no`)}
                            radioButtonProps={{
                                name: "private-loan",
                                value: "false",
                                onChange: () => handleArrayChange("privateLoans", undefined, undefined, 0),
                                checked: privateLoans === undefined || privateLoans?.length <= 0,
                            }}
                        />
                    </Grid>
                </Fieldset>

                {privateLoans && privateLoans.length > 0 && (
                    <React.Fragment>
                        {privateLoans.map(({ privateLoanType, privateLoanValue }, index) => (
                            <Loan
                                // eslint-disable-next-line react/no-array-index-key
                                key={`private-loan-${index}`}
                                index={index}
                                privateLoanValues={{ privateLoanType, privateLoanValue }}
                                handleArrayChange={handleArrayChange}
                            />
                        ))}

                        <Button
                            css={sharedStyles.removeButton}
                            variant="TEXT"
                            icon={
                                theme.widget?.Form?.additionalLoans?.buttons?.remove?.icon && (
                                    <RenderComponentInstance instance={theme.widget?.Form?.additionalLoans?.buttons?.remove?.icon} />
                                )
                            }
                            iconPlacement="left"
                            onClick={() => handleArrayChange("privateLoans", undefined, undefined, privateLoans.length - 1)}
                        >
                            {t("private-loan-remove")}
                        </Button>
                        <Button
                            css={styles.formAddButton}
                            variant="TEXT"
                            icon={
                                theme.widget?.Form?.additionalLoans?.buttons?.add?.icon && (
                                    <RenderComponentInstance instance={theme.widget?.Form?.additionalLoans?.buttons?.add?.icon} />
                                )
                            }
                            iconPlacement="left"
                            onClick={() => handleArrayChange("privateLoans", undefined, undefined, privateLoans.length + 1)}
                        >
                            {t("private-loan-add")}
                        </Button>
                    </React.Fragment>
                )}
            </Section>
        </form>
    );
};

import type { IEvent } from "@Clients/client.types";
import type { IBorrowerValues, IChildValues, IFormValues, IHousingValues, IPrivateLoanValues } from "@Components";

export type EventLabelKeys = keyof IFormValues | IBorrowerValues | IChildValues | IHousingValues | IPrivateLoanValues;

// The keys must be the same as the kays of IFormValues | IBorrowerValues | IChildValues | IHousingValues | IPrivateLoanValues
export const eventLabels = {
    // IFormValues
    customerScenario: "Changed customer scenario",
    prospectType: "Changed prospect type",
    prospectArea: "Changed prospect area",
    municipality: "Changed municipality",
    prospectValuation: "Changed prospect valuation",
    downPayment: "Changed down payment",
    borrowers: "Changed number of borrowers",
    children: "Changed number of children",
    childSupportIncome: "Changed child support income",
    childSupportExpense: "Changed child support expense",
    additionalHousings: "Changed number of additional housings",
    privateLoans: "Changed number of private loans",

    // IBorrowerValues
    borrowerIncome: "Changed borrower income",

    // IChildValues
    childHouseholdRate: "Changed child household rate",

    // IHousingValues
    additionalHousingType: "Changed additional housing type",
    housingFee: "Changed additional housing fee",
    housingMortgage: "Changed additional housing mortgage",

    // IPrivateLoanValues
    privateLoanType: "Changed private loan type",
    privateLoanValue: "Changed private loan value",
};

export function ChangeEventTracking(trackEvent: (event: IEvent) => void, labelKey: EventLabelKeys, data: number | string) {
    trackEvent({
        category: "user",
        action: "input",
        label: eventLabels[labelKey.toString()],
        data,
    });
}

export function ArrayChangeEventTracking(
    trackEvent: (event: IEvent) => void,
    property: keyof IFormValues,
    newValue?: IBorrowerValues | IChildValues | IHousingValues | IPrivateLoanValues,
    newSize?: number,
) {
    // Array size changed
    if (newSize) {
        ChangeEventTracking(trackEvent, property, newSize);
    }

    // Data in array changed
    if (newValue !== undefined) {
        const newValueKey = Object.keys(newValue)[0] as EventLabelKeys;
        ChangeEventTracking(trackEvent, newValueKey, newValue[newValueKey.toString()]);
    }
}

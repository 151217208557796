import type { ILinkProps } from "@econans/ui-components";
import { Link } from "@econans/ui-components";

interface ITransLinkProps extends Omit<ILinkProps, "href" | "icon"> {
    to?: string;
}

/**
 * TransLink is a wrapper around Link from UI Components intended to be
 * used together with the Trans component from react-i18next. The wrapper
 * is needed to provide a more user (client) friendly API (props),
 * validation and avoid type errors.
 * Links are by default opened in a new window to prevent interupting
 * an end users usage of our widgets.
 */

export const TransLink: React.FC<ITransLinkProps> = ({ to, target, children, ...rest }) => {
    if (!to || !children) return null;
    return (
        <Link href={to} target={target || "_blank"} {...rest}>
            {children}
        </Link>
    );
};

export const sampleHelper = (sampleArg: string) => sampleArg.toLowerCase();

const toPercent = (amount: number) => amount * 100;

export function formatLocalAmount(value: number, minDecimals = 0, maxDecimals = 0, locale = "sv-SE", options?: Intl.NumberFormatOptions): string {
    const formatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
        ...(options || {}),
    });

    return formatter.format(value);
}

export function formatLocalPercent(value: number, decimals = 2): string {
    return toPercent(value).toFixed(decimals).replace(".", ",");
}

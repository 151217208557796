import { BorrowingSpaceModels } from "@econans/calculations";

export const defaultChildBenefits: BorrowingSpaceModels.IChildBenefit = {
    benefitPerChild: 1250,
    additionForMultipleChildren: {
        twoChildren: 150,
        threeChildren: 730,
        fourChildren: 1740,
        fiveChildren: 2990,
    },
};

import { useState, useMemo } from "react";
import { BorrowingSpaceCalc } from "@econans/calculations";
import {
    AdditionalHousingType,
    ArrayChangeEventTracking,
    ChangeEventTracking,
    CustomerScenario,
    PrivateLoanType,
    ProspectType,
    useConfiguration,
    useEvent,
} from "@Utils";
import { calculateBorrowingSpace } from "@Calculations";
import type { IFormProps, IFormValues, IResultValues } from "@Components";
import { Form } from "../Form";
import { Result } from "../Result";
import { AppLayout } from "./App.styles";

// TODO: (Pontus) Move this somewhere else? Only need to be fetched once
const municipalities = BorrowingSpaceCalc.getMunicipalities();

export const App = () => {
    const configuration = useConfiguration();
    const { trackEvent } = useEvent(configuration);
    const { defaultFormValues } = configuration;

    const [formValues, setFormValues] = useState<IFormValues>({
        customerScenario: defaultFormValues?.customerScenario ?? CustomerScenario.MOVE_EXISTING_MORTGAGE,
        prospectType: defaultFormValues?.prospectType ?? ProspectType.HOUSE,
        condominiumFee: defaultFormValues?.condominiumFee ?? 0,
        prospectArea: defaultFormValues?.prospectArea ?? 0,
        maintenanceCost: defaultFormValues?.maintenanceCost ?? 0,
        prospectValuation: defaultFormValues?.prospectValuation ?? 0,
        downPayment: defaultFormValues?.downPayment ?? 0,
        childSupportIncome: 0,
        childSupportExpense: 0,
        municipality:
            municipalities.find((municipality) => municipality.name.toLowerCase() === defaultFormValues?.municipality?.toLowerCase())?.name || "",
    });

    const handleArrayChange: IFormProps["handleArrayChange"] = (property, newValue, index, newSize) => {
        setFormValues((previousValues: IFormValues) => {
            const newArrayNeeded = previousValues[property] === undefined;
            const newValues = newArrayNeeded ? { ...previousValues, [property]: [] } : { ...previousValues };

            let defaultObject;

            switch (property) {
                case "borrowers":
                    defaultObject = { borrowerIncome: 0 };
                    break;
                case "children":
                    defaultObject = { childHouseholdRate: BorrowingSpaceCalc.ChildHouseholdRate.FULL_TIME };
                    break;
                case "additionalHousings":
                    defaultObject = { additionalHousingType: AdditionalHousingType.CHOOSE, housingFee: 0, housingMortgage: 0 };
                    break;
                case "privateLoans":
                    defaultObject = { privateLoanType: PrivateLoanType.CHOOSE, privateLoanValue: 0 };
                    break;
                default:
                    break;
            }

            const newValuesProperty = newValues[property];
            if (typeof newValuesProperty !== "undefined") {
                if (Number.isFinite(index) && typeof index === "number" && typeof newValuesProperty[index] !== "undefined") {
                    newValuesProperty[index] = { ...newValuesProperty[index], ...newValue };
                } else if (Array.isArray(newValuesProperty) && typeof newSize === "number") {
                    while (newValuesProperty.length < newSize) {
                        newValuesProperty.push(defaultObject);
                    }
                    while (newValuesProperty.length > newSize) {
                        newValuesProperty.pop();
                    }
                }
            }

            // If no children, child support income not possible
            if (property === "children" && typeof newSize === "number" && newSize <= 0) {
                newValues.childSupportIncome = 0;
            }

            ArrayChangeEventTracking(trackEvent, property, newValue, newSize);

            return newValues;
        });
    };

    const handleChange: IFormProps["handleChange"] = (property, newValue) => {
        if (newValue === null) return;

        setFormValues((previousValues: IFormValues) => ({ ...previousValues, [property]: newValue }));
        ChangeEventTracking(trackEvent, property, newValue);
    };

    const resultValues = useMemo(
        (): IResultValues => calculateBorrowingSpace(formValues, configuration, municipalities),
        [formValues, configuration],
    );

    return (
        <AppLayout.Wrapper>
            <AppLayout.Content>
                <Form formValues={formValues} handleChange={handleChange} handleArrayChange={handleArrayChange} />
            </AppLayout.Content>
            <AppLayout.Content isSticky>
                <Result formValues={formValues} resultValues={resultValues} />
            </AppLayout.Content>
        </AppLayout.Wrapper>
    );
};

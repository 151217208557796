import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

const label = (theme: Theme) => css`
    margin-top: ${theme.spacing(6)};
`;

const topBorder = (theme: Theme) => css`
    width: 100%;
    padding-top: 25px;
    border-top: 1px solid ${theme.widget?.Form?.additionalHousings?.buttons?.spacer?.color ?? "#000000"};
`;

const removeButton = (theme: Theme) => css`
    width: 100%;
    margin-top: 24px;
    position: relative;
    justify-content: center;
    gap: 8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;

    :after {
        position: absolute;
        bottom: -24px;
        content: '';
        border-bottom: 1px solid ${theme.widget?.Form?.additionalHousings?.buttons?.spacer?.color ?? "#000000"};
        width: 100%;
        display: block;
    }
`;

const smallButton = (theme: Theme) => css`
    span {
        ${theme.widget?.Form?.additionalHousings?.radioButtons?.narrowGrid ? `padding: ${theme.spacing(4, 8)};` : ""}
    }

    ${theme.mq({
        theme,
        max: "DESKTOP",
        css: `
        width: 100%;
        `,
    })}
`;

const visuallyHidden = () => css`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
`;

export const Section = styled.section`
    all: revert;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0;
`;

export const sharedStyles = { label, topBorder, removeButton, smallButton, visuallyHidden };

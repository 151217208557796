import type { IClientConfiguration } from "@Clients/client.types";
import { EmotionConfiguration } from "@econans/ui-components";
import { Theme } from "@emotion/react";
import { ConfigurationProvider } from "@Utils";
import { App } from "@Components/App";

interface IApplicationWrapperProps {
    configuration: IClientConfiguration;
    theme: Theme;
}

export const ApplicationWrapper: React.FC<IApplicationWrapperProps> = ({ configuration, theme }) => (
    <ConfigurationProvider value={configuration}>
        <EmotionConfiguration namespace="borrowing-space" theme={theme}>
            <App />
        </EmotionConfiguration>
    </ConfigurationProvider>
);

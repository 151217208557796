import React from "react";
import { useTranslation } from "react-i18next";
import { Label, Select, IntegerInput } from "@econans/ui-components";
import type { IPrivateLoanValues, IFormValues } from "@Components";
import { PrivateLoanType, TranslationNamespaceEnum, useConfiguration } from "@Utils";
import { sharedStyles } from "../sharedStyles";

interface ILoanProps {
    index: number;
    privateLoanValues: IPrivateLoanValues;
    handleArrayChange(property: keyof IFormValues, newValue: any, index: number): void;
}

const getLoanTypeTranslation = (privateLoanType: PrivateLoanType) => {
    switch (privateLoanType) {
        case PrivateLoanType.ACCOUNT_CREDIT:
            return "granted-credit";
        case PrivateLoanType.STUDENT_LOAN:
            return "monthly-cost";
        default:
            return "current-debt";
    }
};

export const Loan: React.FC<ILoanProps> = ({ index, privateLoanValues, handleArrayChange }) => {
    const { clearOnFocusIfZero } = useConfiguration();
    const { privateLoanType, privateLoanValue } = privateLoanValues;
    const { t } = useTranslation([TranslationNamespaceEnum.FORM, TranslationNamespaceEnum.SUFFIXES]);

    const [firstOption, ...rest] = (Object.keys(PrivateLoanType) as Array<PrivateLoanType>).map((type) => ({
        label: t(`private-loan-type-${PrivateLoanType[type]}`),
        value: PrivateLoanType[type],
    }));
    const isStudenLoan = privateLoanType === PrivateLoanType.STUDENT_LOAN;

    return (
        <React.Fragment>
            <Label css={[sharedStyles.label, index > 0 && sharedStyles.topBorder]} htmlFor={`econans-borrowing-space-private-loan-type-${index}`}>
                {t("private-loan-type-label")}
            </Label>
            <Select
                id={`econans-borrowing-space-private-loan-type-${index}`}
                options={[{ label: firstOption.label, value: firstOption.value, disabled: true }, ...rest]}
                value={privateLoanType ?? firstOption.value}
                onChange={(event) => handleArrayChange("privateLoans", { privateLoanType: event.target.value }, index)}
            />
            <React.Fragment>
                <Label css={sharedStyles.label} htmlFor={`econans-borrowing-space-private-loan-value-${index}`}>
                    {t(getLoanTypeTranslation(privateLoanType))}
                </Label>
                <IntegerInput
                    id={`econans-borrowing-space-private-loan-value-${index}`}
                    value={privateLoanValue}
                    max={isStudenLoan ? 50000 : 50000000}
                    step={isStudenLoan ? 1000 : 100000}
                    onChange={(newValue) => handleArrayChange("privateLoans", { privateLoanValue: newValue }, index)}
                    suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:${isStudenLoan ? "sekPerMonth" : "sek"}`)}
                    clearOnFocusIfZero={clearOnFocusIfZero}
                />
            </React.Fragment>
        </React.Fragment>
    );
};

import type { IClientConfiguration } from "@Clients/client.types";
import type { BorrowingSpaceModels } from "@econans/calculations";
import { type IFormValues, type IResultValues } from "@Components";
import { BorrowingSpaceCalc } from "@econans/calculations";
import { CustomerScenario, formatLocalAmount, formatLocalPercent, ProspectType } from "@Utils";
import { mapLocalizationParameters } from "@Functions/localizationValues";
import { getAdditionalHousingCosts, getAdults, getChildren, getHousingValueAndMortgageAmount, getMortgage, getPrivateLoans } from "./conversions";
import { defaultChildBenefits } from "../clients/defaultValues";

const MONTHS_IN_YEAR = 12;

function areMinimumInputRequirementsMet(formValues: IFormValues, configuration: IClientConfiguration): boolean {
    let propspectAreaValid = false;
    if (formValues.prospectType === ProspectType.HOUSE && !!configuration.calculateMaintenanceCostFromHousingArea) {
        propspectAreaValid = formValues.prospectArea > 0;
    } else {
        // Prospect area not relevant if maintenance cost field is in use
        propspectAreaValid = true;
    }

    const hasAtLeastOneIncome = formValues.borrowers?.some((borrower) => borrower.borrowerIncome > 0);

    return Boolean(
        formValues.customerScenario &&
            formValues.prospectType &&
            propspectAreaValid &&
            formValues.municipality &&
            (formValues.prospectValuation || formValues.downPayment) &&
            hasAtLeastOneIncome,
    );
}

function getTaxTable(municipalitiesList: Array<BorrowingSpaceModels.IMunicipality>, municipality: string | undefined): number {
    return municipalitiesList.find((municipalityItem) => municipalityItem.name === municipality)?.taxTable ?? -1;
}

function setChildBenefits(configChildBenefits?: BorrowingSpaceModels.IChildBenefit): BorrowingSpaceModels.IChildBenefit {
    return configChildBenefits ?? defaultChildBenefits;
}

export function calculateBorrowingSpace(
    formValues: IFormValues,
    configuration: IClientConfiguration,
    municipalities: Array<BorrowingSpaceModels.IMunicipality>,
): IResultValues {
    if (!areMinimumInputRequirementsMet(formValues, configuration)) {
        return {
            customerScenario: formValues.customerScenario,
        };
    }

    const {
        additionalMortgageInterestRate,
        additionalMortgageAmortizationRate,
        minDownPaymentRatio,
        calculationInterestRate,
        accountCreditPaidOffYears,
        accountCreditInterest,
        instalmentCreditPaidOffYears,
        instalmentCreditInterest,
        unsecuredLoanPaidOffYears,
        unsecuredLoanInterest,
        fixedLoanToValueAmortizationRate,
        minMortgageDiscountLimit,
        maxPropertyTax,
        listInterest,
        maxDebtByYearIncomeFactor,
        childBenefit: configChildBenefits,
    } = configuration.calculationParameters;

    const defaultLocalizationParameters = BorrowingSpaceCalc.Forecast.getDefaultLocalizationParameters();
    const localizationParameters = mapLocalizationParameters(defaultLocalizationParameters, configuration.calculationParameters);

    const { calculateAdditionalHousingMaintenanceCost, calculateMaintenanceCostFromHousingArea } = configuration;
    const additionalHousing = getAdditionalHousingCosts(
        formValues.additionalHousings,
        additionalMortgageInterestRate,
        additionalMortgageAmortizationRate,
        calculateAdditionalHousingMaintenanceCost,
    );

    const taxTable = getTaxTable(municipalities, formValues.municipality);

    const [housingValue, mortgageAmount] = getHousingValueAndMortgageAmount(
        formValues.customerScenario,
        formValues.downPayment,
        formValues.prospectValuation,
        minDownPaymentRatio,
    );

    // No need for amortization in main mortgage, market amortization requirements is used
    const borrowingSpaceMortgage = getMortgage(mortgageAmount, calculationInterestRate, 0);

    const monthlyFee = formValues.prospectType === ProspectType.CONDOMINIUM ? formValues.condominiumFee : 0;
    // ToDo: Change 1000 to maintenance cost from form component when created.
    const monthlyMaintenance = calculateMaintenanceCostFromHousingArea
        ? calculateMaintenanceCostFromHousingArea(formValues.prospectArea, formValues.prospectType)
        : formValues.maintenanceCost;

    const propertyTax =
        formValues.prospectType === ProspectType.CONDOMINIUM
            ? 0
            : BorrowingSpaceCalc.calculatePropertyTax(maxPropertyTax, housingValue, formValues.customerScenario === CustomerScenario.BUY_NEW_HOUSING);

    const adults = getAdults(formValues.borrowers);

    const children = getChildren(formValues.children);

    const childBenefit = setChildBenefits(configChildBenefits);

    const privateLoans = getPrivateLoans(
        formValues.privateLoans,
        accountCreditPaidOffYears,
        accountCreditInterest,
        instalmentCreditPaidOffYears,
        instalmentCreditInterest,
        unsecuredLoanPaidOffYears,
        unsecuredLoanInterest,
    );

    // Create indata for borrowing space and forecast
    const userForecastInput: BorrowingSpaceModels.IForecastInput = {
        housingValue,
        amortizationType: BorrowingSpaceCalc.AmortizationType.MARKET_SPECIFIC,
        mortgage: borrowingSpaceMortgage,
        monthlyMaintenance,
        monthlyFee,
        propertyTax,
        adults,
        children,
        childSupport: {
            income: formValues.childSupportIncome,
            expense: formValues.childSupportExpense,
        },
        carCost: 0,
        savings: [],
        privateLoans,
        childBenefit,
        additionalHousing,
        calculationInterestRate,
        taxTable,
        customLoanToValueAmortizationRate: fixedLoanToValueAmortizationRate,
    };
    const isNewHousingScenario = formValues.customerScenario === CustomerScenario.BUY_NEW_HOUSING;

    const roundingPrecision = 1000; // Smallest deminimator for rounding
    // Run borrowing space calculation function
    const borrowingSpaceResult = BorrowingSpaceCalc.estimateBorrowingSpace(
        userForecastInput,
        isNewHousingScenario,
        minDownPaymentRatio,
        localizationParameters,
        roundingPrecision,
        maxDebtByYearIncomeFactor,
    );

    let bestInterestData: BorrowingSpaceModels.IBestInterestData;
    if (borrowingSpaceResult.maxMortgage >= minMortgageDiscountLimit) {
        bestInterestData = BorrowingSpaceCalc.getBestInterestDataByLTV(
            listInterest,
            borrowingSpaceResult.housingPrice,
            borrowingSpaceResult.maxMortgage,
        );
    } else {
        // Use same housing price (1) and mortgage amount (1) to disregad any interest disscounts due to LTV.
        bestInterestData = BorrowingSpaceCalc.getBestInterestDataByLTV(listInterest, 1, 1);
    }

    // Adjust input data to monthly cost forecast with the borrowing space offer
    const maxLoanMortgage = getMortgage(borrowingSpaceResult.maxMortgage, bestInterestData.interestWithDiscount, 0);
    const forecastInput = {
        ...userForecastInput,
        housingValue: borrowingSpaceResult.housingPrice,
        mortgage: maxLoanMortgage,
        customLoanToValueAmortizationRate: undefined,
    };
    const monthlyForecast = new BorrowingSpaceCalc.Forecast().forecastAll(forecastInput, MONTHS_IN_YEAR).forecast[0];

    return {
        customerScenario: formValues.customerScenario,
        maxHousingPrice: formatLocalAmount(borrowingSpaceResult.housingPrice),
        downPayment: formatLocalAmount(borrowingSpaceResult.downPayment),
        maxLoanAmount: formatLocalAmount(borrowingSpaceResult.maxMortgage),
        monthlyCostAfterDeduction: formatLocalAmount(monthlyForecast.housingExpensesAfterTaxDeduction),
        monthlyCostBeforeDeduction: formatLocalAmount(monthlyForecast.housingExpensesBeforeTaxDeduction),
        listInterestPeriod: bestInterestData.period.slice(0, 5),
        nominalInterestExpense: formatLocalAmount(monthlyForecast.totalMonthlyInterestBeforeDeduction),
        nominalInterestRate: formatLocalPercent(bestInterestData.interestWithDiscount),
        effectiveInterestRate: formatLocalPercent(bestInterestData.effectiveInterest),
        amortizationExpense: formatLocalAmount(monthlyForecast.totalMonthlyAmortization),
        amortizationRate: formatLocalPercent(monthlyForecast.amortizationRate, 0),
        maintenanceExpense: formatLocalAmount(monthlyForecast.maintenance),
        condominiumFee: formatLocalAmount(monthlyForecast.fee),
        propertyTax: formatLocalAmount(monthlyForecast.propertyTax),
    };
}

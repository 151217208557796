import React from "react";
import { useTranslation } from "react-i18next";
import { css, useTheme } from "@emotion/react";
import { DisguisedRadioButton, Fieldset, Grid, Legend } from "@econans/ui-components";
import { BorrowingSpaceCalc } from "@econans/calculations";

import type { IChildValues, IFormValues } from "@Components";
import { TranslationNamespaceEnum } from "@Utils";
import { sharedStyles } from "../sharedStyles";
import { styles as formStyles } from "../Form/Form.styles";

interface IChildProps {
    index: number;
    childValues: IChildValues;
    handleArrayChange(property: keyof IFormValues, newValue: any, index: number): void;
}

const { ChildHouseholdRate } = BorrowingSpaceCalc;

export const Child: React.FC<IChildProps> = ({ index, childValues, handleArrayChange }) => {
    const { t } = useTranslation([TranslationNamespaceEnum.FORM]);
    const theme = useTheme();

    const { childHouseholdRate } = childValues;

    return (
        <Fieldset css={[formStyles.formFieldset, css`margin-top: ${theme.spacing(6)};`]}>
            <Legend css={formStyles.formFieldsetLegend}>{t("child-label", { number: index + 1 })}</Legend>
            <Grid columns={2} gap={theme.spacing(4)}>
                <DisguisedRadioButton
                    css={sharedStyles.smallButton}
                    content={t("child-FULL_TIME")}
                    radioButtonProps={{
                        name: `child-${index}-household-rate`,
                        value: "false",
                        onChange: () => handleArrayChange("children", { childHouseholdRate: ChildHouseholdRate.FULL_TIME }, index),
                        checked: childHouseholdRate === ChildHouseholdRate.FULL_TIME,
                    }}
                />
                <DisguisedRadioButton
                    css={sharedStyles.smallButton}
                    content={t("child-PART_TIME")}
                    radioButtonProps={{
                        name: `child-${index}-household-rate`,
                        value: "false",
                        onChange: () => handleArrayChange("children", { childHouseholdRate: ChildHouseholdRate.HALF_TIME }, index),
                        checked: childHouseholdRate === ChildHouseholdRate.HALF_TIME,
                    }}
                />
            </Grid>
        </Fieldset>
    );
};

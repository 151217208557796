import React from "react";
import { useTranslation } from "react-i18next";
import { Label, Select, IntegerInput } from "@econans/ui-components";
import type { IHousingValues, IFormValues } from "@Components";
import { AdditionalHousingType, TranslationNamespaceEnum, useConfiguration } from "@Utils";
import { sharedStyles } from "../sharedStyles";

const { CONDOMINIUM, RENTAL_APARTMENT, HOUSE, COTTAGE, AGRICULTIRAL_ESTATE, OTHER } = AdditionalHousingType;

const getTranslationFromHousingType = (additionalHousingType: AdditionalHousingType): string => {
    switch (additionalHousingType) {
        case AdditionalHousingType.CONDOMINIUM:
            return "additional-housing-monthly-fee";
        case AdditionalHousingType.RENTAL_APARTMENT:
            return "additional-housing-rent";
        default:
            return "additional-housing-expense";
    }
};

const hasHousingFee = (additionalHousingType: AdditionalHousingType): boolean =>
    additionalHousingType === CONDOMINIUM || additionalHousingType === RENTAL_APARTMENT || additionalHousingType === OTHER;

const hasHousingMortgage = (additionalHousingType: AdditionalHousingType): boolean =>
    additionalHousingType === CONDOMINIUM ||
    additionalHousingType === HOUSE ||
    additionalHousingType === COTTAGE ||
    additionalHousingType === AGRICULTIRAL_ESTATE;

interface IHousingProps {
    index: number;
    housingValues: IHousingValues;
    handleArrayChange(property: keyof IFormValues, newValue: any, index: number): void;
}

export const Housing: React.FC<IHousingProps> = ({ index, housingValues, handleArrayChange }) => {
    const { clearOnFocusIfZero } = useConfiguration();
    const { additionalHousingType, housingFee, housingMortgage } = housingValues;
    const { t } = useTranslation([TranslationNamespaceEnum.FORM, TranslationNamespaceEnum.SUFFIXES]);

    const [firstOption, ...rest] = (Object.keys(AdditionalHousingType) as Array<AdditionalHousingType>).map((type) => ({
        label: t(`additional-housing-type-${AdditionalHousingType[type]}`),
        value: AdditionalHousingType[type],
    }));

    return (
        <React.Fragment>
            <Label css={[sharedStyles.label, index > 0 && sharedStyles.topBorder]} htmlFor={`econans-borrowing-space-additional-housing-${index}`}>
                {t("additional-housing-type-label")}
            </Label>
            <Select
                id={`econans-borrowing-space-additional-housing-${index}`}
                options={[{ label: firstOption.label, value: firstOption.value, disabled: true }, ...rest]}
                value={additionalHousingType ?? firstOption.value}
                onChange={(event) =>
                    handleArrayChange("additionalHousings", { additionalHousingType: event.target.value, housingFee: 0, housingMortgage: 0 }, index)
                }
            />
            {hasHousingFee(additionalHousingType) && (
                <React.Fragment>
                    <Label css={sharedStyles.label} htmlFor={`econans-borrowing-space-additional-housing-fee-${index}`}>
                        {t(getTranslationFromHousingType(additionalHousingType))}
                    </Label>
                    <IntegerInput
                        id={`econans-borrowing-space-additional-housing-fee-${index}`}
                        value={housingFee ?? 0}
                        max={50000}
                        step={1000}
                        onChange={(newValue) => handleArrayChange("additionalHousings", { housingFee: newValue }, index)}
                        suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}
                        clearOnFocusIfZero={clearOnFocusIfZero}
                    />
                </React.Fragment>
            )}
            {hasHousingMortgage(additionalHousingType) && (
                <React.Fragment>
                    <Label css={sharedStyles.label} htmlFor={`econans-borrowing-space-additional-housing-mortgage-${index}`}>
                        {t("additional-housing-loan")}
                    </Label>
                    <IntegerInput
                        id={`econans-borrowing-space-additional-housing-mortgage-${index}`}
                        value={housingMortgage ?? 0}
                        max={50000000}
                        step={100000}
                        onChange={(newValue) => handleArrayChange("additionalHousings", { housingMortgage: newValue }, index)}
                        suffix={t(`${TranslationNamespaceEnum.SUFFIXES}:sek`)}
                        clearOnFocusIfZero={clearOnFocusIfZero}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

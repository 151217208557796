import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { BreakpointEnum, ICSSProperties } from "@econans/ui-components";

const Wrapper = styled.div`
    all: revert;
    box-sizing: border-box;
    position: relative;
    
    ${({ theme }) => `
        display: grid;
        gap: ${theme.widget?.App?.wrapper?.gap ?? theme.spacing(5)};
        margin: 0;
        padding: 0;
        max-width: 1280px;

        ${theme.mq({
            theme,
            min: "DESKTOP",
            css: `
                grid-template-columns: 1fr 1fr;
                align-items: start;
                ${theme.widget?.App?.wrapper?.desktop?.gap != null ? `gap: ${theme.widget.App.wrapper.desktop.gap};` : ""};
            `,
        })}
    `}
`;

const Content = styled("section", {
    shouldForwardProp: (prop) => isPropValid(prop),
})<{ isSticky?: boolean }>`
    all: revert;
    box-sizing: border-box;
    display: block;

    ${({ theme, isSticky }) => `
        ${
            isSticky
                ? `
                    position: sticky;
                    top: 0;
                `
                : ""
        }    
        margin: 0;
        padding: ${theme.widget?.App?.blocks?.padding ?? theme.spacing(4, 2, 6)};
        background: ${theme.widget?.App?.blocks?.background ?? "#fff"};
        border: ${theme.widget?.App?.blocks?.border ?? "none"};

        ${theme.mq({
            theme,
            min: "DESKTOP",
            css: `
                ${theme.widget?.App?.blocks?.desktop?.padding != null ? `padding: ${theme.widget.App.blocks.desktop.padding};` : ""}
                ${theme.widget?.App?.blocks?.desktop?.background != null ? `background: ${theme.widget.App.blocks.desktop.background};` : ""}
                ${theme.widget?.App?.blocks?.desktop?.border != null ? `border: ${theme.widget.App.blocks.desktop.border};` : ""}
            `,
        })}
    `}
`;

export const AppLayout = {
    Wrapper,
    Content,
};

export interface IAppStyleOverrides {
    wrapper?: Pick<ICSSProperties, "gap"> & { [BreakpointEnum.DESKTOP]?: Pick<ICSSProperties, "gap"> };
    blocks?: Pick<ICSSProperties, "padding" | "background" | "border"> & {
        sections?: {
            gap?: ICSSProperties["gap"];
        };
        [BreakpointEnum.DESKTOP]?: Pick<ICSSProperties, "padding" | "background" | "border">;
    };
}

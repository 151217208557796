import { Theme, css } from "@emotion/react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { DescriptionList, DescriptionTerm, DescriptionDetail, ICSSProperties } from "@econans/ui-components";

const result = (theme: Theme) => css`
    all: revert;
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: rows;
    gap: ${theme.widget?.App?.blocks?.sections?.gap ?? theme.spacing(10)};
    margin: 0;
    padding: 0;

    ${/** TODO: Define in theme when DescriptionDetail support the justify-content property */ ""}
    dd {
        text-align: right;
        justify-content: flex-end;
    }
`;

const heading = css`
    text-align: center;
`;

const loanAmount = (theme: Theme) => css`
    display: block;
    margin-bottom: ${theme.spacing(3)};
    font-family: ${theme.typography.font.secondary};
    font-size: ${theme.typography.size.xl};
    font-weight: normal;
    line-height: 1.44;
    color: ${theme.typography.color.primary};
`;

const housingValue = (theme: Theme) => css`
    margin-bottom: ${theme.spacing(4)};
    font-family: ${theme.typography.font.secondary};
`;

const monthlyCostAfterTaxDeduction = (theme: Theme) => css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
    font-family: ${theme.typography.font.secondary};
    font-size: ${theme.typography.size.xl};
    font-weight: normal;
    line-height: 1.44;
    color: ${theme.typography.color.primary};
`;

const primaryCtaButton = (theme: Theme) => css`
    justify-content: center;
    margin: ${theme.widget?.Result?.cta?.primary?.margin ?? theme.spacing(6, 0, 0)}
`;

const secondaryCtaButton = (theme: Theme) => css`
    justify-content: center;
    margin: ${theme.widget?.Result?.cta?.secondary?.margin ?? theme.spacing(6, 0, 0)}
`;

export const ResultDescriptionList = styled(DescriptionList)`
    grid-template-columns: 1fr auto minmax(90px, max-content);
`;

type BottomSpacing = "sm" | "md" | "lg";
const getBottomSpacingValue = (spacing: BottomSpacing, theme: Theme) => {
    switch (spacing) {
        case "sm":
            return theme.spacing(1);
        case "md":
            return theme.spacing(2);
        case "lg":
            return theme.spacing(4);
        default:
            return 0;
    }
};

const sharedTermAndDetailStyles = ({
    theme,
    bottomSpacing,
    coverAllColumns,
    boldText,
}: {
    theme: Theme;
    bottomSpacing?: BottomSpacing;
    coverAllColumns?: boolean;
    boldText?: boolean;
}) => css`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: ${theme.spacing(2)};
    font-family: ${theme.typography.font[boldText ? "secondary" : "primary"]};
    white-space: nowrap;
    ${bottomSpacing ? `padding-bottom: ${getBottomSpacingValue(bottomSpacing, theme)};` : ``}
    ${coverAllColumns ? `grid-column: 1 / -1;` : ``}
`;

export const ResultDescriptionTerm = styled(DescriptionTerm, {
    shouldForwardProp: (prop) => isPropValid(prop),
})<{
    bottomSpacing?: BottomSpacing;
    coverAllColumns?: boolean;
    boldText?: boolean;
}>`
    ${sharedTermAndDetailStyles}
    ${({ coverAllColumns }) => (!coverAllColumns ? `grid-column: 1;` : ``)}
`;

export const ResultDescriptionDetail = styled(DescriptionDetail, {
    shouldForwardProp: (prop) => isPropValid(prop),
})<{
    bottomSpacing?: BottomSpacing;
    placeInFirstColumn?: boolean;
    placeInSecondColumn?: boolean;
    coverAllColumns?: boolean;
    boldText?: boolean;
}>`
    ${sharedTermAndDetailStyles}
    ${({ placeInFirstColumn, placeInSecondColumn }) => `
        ${placeInFirstColumn ? `grid-column: 2;` : ``}
        ${placeInSecondColumn ? `grid-column: 3;` : ``}
    `}
`;

export const toolTipModal = (theme: Theme) => css`
    ${theme.mq({
        theme,
        max: "DESKTOP",
        css: `
            button {
                width: 28px;
            }
        `,
    })}
`;
interface ICtaStyleOverrides {
    icon?: React.ComponentType;
    margin?: ICSSProperties["margin"];
}

export interface IResultStyleOverrides {
    cta: {
        primary: ICtaStyleOverrides;
        secondary: ICtaStyleOverrides;
    };
}

export const styles = {
    result,
    heading,
    primaryCtaButton,
    secondaryCtaButton,
    loanAmount,
    housingValue,
    monthlyCostAfterTaxDeduction,
    toolTipModal,
};

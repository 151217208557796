import { TranslationResources, LanguageKeyEnum, TranslationNamespaceEnum } from "./translations.types";

export const defaultTranslations: TranslationResources = {
    [LanguageKeyEnum.SV]: {
        [TranslationNamespaceEnum.FORM]: {
            "housing-heading": "Boende",
            "customer-scenario-label": "Jag vill",
            "customer-scenario-option-move-existing-mortgage": "Flytta mitt bolån",
            "customer-scenario-option-buy-new-housing": "Köpa ny bostad",
            "prospect-type-label": "Typ av boende",
            "prospect-type-HOUSE": "Hus",
            "prospect-type-COTTAGE": "Fritidshus",
            "prospect-type-CONDOMINIUM": "Bostadsrätt",
            "monthly-fee-label": "Bostadsrättsavgift",
            "prospect-area-label": "Bostadens boyta",
            "maintenance-cost-label": "Driftkostnad",
            "municipality-label": "Kommun",
            "municipality-choose": "Välj kommun",
            "loan-heading": "Lån",
            "housing-valuation-label": "Bostadens värde",
            "downPayment-label": "Kontantinsats",
            "borrower-label": "Låntagare",
            "borrower-one": "Vi är en som söker",
            "borrower-two": "Vi är två som söker",
            "borrower-income-label": "Månadsinkomst före skatt, sökande {{number}}",
            "children-heading": "Barn i hushållet",
            "children-number-label": "Antal barn under {{maxChildAge}} år som bor hemma",
            "child-label": "Barn {{number}}, bor i hushållet på",
            "child-FULL_TIME": "Heltid",
            "child-PART_TIME": "Deltid",
            "incoming-child-support": "Jag får underhållsbidrag",
            "outgoing-child-support": "Jag betalar underhållsbidrag",
            "additional-housing-heading": "Har du bostäder som ska behållas?",
            "additional-housing-type-label": "Typ av bostad",
            "additional-housing-type-CHOOSE": "Välj typ av bostad",
            "additional-housing-type-CONDOMINIUM": "Bostadsrätt",
            "additional-housing-type-RENTAL_APARTMENT": "Hyresrätt",
            "additional-housing-type-HOUSE": "Villa",
            "additional-housing-type-COTTAGE": "Fritidshus",
            "additional-housing-type-AGRICULTIRAL_ESTATE": "Lantbruk",
            "additional-housing-type-OTHER": "Annan typ av bostad",
            "additional-housing-rent": "Hyra",
            "additional-housing-expense": "Boendekostnad",
            "additional-housing-monthly-fee": "Bostadsrättsavgift",
            "additional-housing-loan": "Lån på bostaden (som inte ska lösas med det nya lånet)",
            "additional-housing-remove": "Ta bort bostaden",
            "additional-housing-add": "Lägg till ytterligare bostad",
            "private-loan-heading": "Har du några övriga lån som ska behållas?",
            "private-loan-type-label": "Typ av lån",
            "private-loan-type-CHOOSE": "Välj typ av lån",
            "current-debt": "Aktuell skuld",
            "monthly-cost": "Månadskostnad",
            "granted-credit": "Beviljad kredit",
            "private-loan-type-ACCOUNT_CREDIT": "Konto-/Kortkredit",
            "private-loan-type-INSTALMENT_CREDIT": "Avbetalningskredit/Billån/Båtlån",
            "private-loan-type-STUDENT_LOAN": "Studentlån",
            "private-loan-type-UNSECURED_LOAN": "Blancolån/Övrigt",
            "private-loan-remove": "Ta bort lånet",
            "private-loan-add": "Lägg till ytterligare lån",
        },
        [TranslationNamespaceEnum.RESULT]: {
            heading: "Bolånekalkyl",
            "prelimiary-loan-label": "Du kan preliminärt låna",
            "prelimiary-buy-label": "Du kan preliminärt köpa för",
            "housing-value": "Bostadens värde",
            downPayment: "Kontantinsats",
            "max-loan": "Max lånebelopp",
            "monthly-cost-after-tax-deduction": "Preliminär månadskostnad för din bostad",
            "monthly-cost-before-tax-deduction": "Före skatteavdrag",
            "calculation-explanation-label": "Så har vi räknat",
            "nominal-interest": "Din ränta {{listInterestPeriod}}",
            "effective-interest": "Effektiv ränta",
            amortization: "Amortering",
            "condominium-fee": "Bostadsrättsavgift",
            "operating-cost": "Driftkostnad",
            "property-tax": "Fastighetsavgift",
            "primary-cta-button": "Ansök om lånelöfte",
            "secondary-cta-button": "Ansök om bolån direkt",
        },
        [TranslationNamespaceEnum.GENERIC]: {
            yes: "Ja",
            no: "Nej",
        },
        [TranslationNamespaceEnum.DISCLAIMER]: {
            "max-loan-explanation": `
                Vad som anges ovan är preliminära uppgifter och utgör inget bindande erbjudande om kredit. 
                För att få ett erbjudande om kredit, gå vidare och ansök om bolån.
            `,
            "below-min-mortgage-threshold": `
                Minsta lånebelopp hos oss är <nobr>{{minMortgageAllowed}} kr.</nobr> Baserat på de uppgifter du angett har vi inte möjlighet att ge 
                dig ett lån. Kontakta oss för mer information.`,
            "calculation-explanation": `
                Beräkningen är preliminär och visar för närvarande gällande ränta. När du skickar in din ansökan kommer det att göras en 
                kreditprövning med en högre kalkylränta och se över amorteringsbeloppet för att säkerställa betalningsförmågan även på lång sikt.
            `,
        },
        [TranslationNamespaceEnum.INFO]: {
            "interest-heading": "Din ränta",
            interest: `<p>Räntan inkluderar rabatt baserat på belåningsgrad.</p>`,
            "amortization-heading": "Amortering",
            amortization: `
                <p>Hur mycket hushållet behöver amortera på bolånet beror på vilket amorteringsregelverk lånet har, belåningsgraden och hushållets 
                totala inkomst.</p>
                <p>Bolån med en belåningsgrad om <nobr>50-70 %</nobr> ska amorteras med minst <nobr>1 %</nobr> per år.</p>
                <p>Bolån med en belåningsgrad över <nobr>70 %</nobr> av bostadens värde amorteras med <nobr>2 %</nobr> per år.</p>
                <p>Utöver detta behöver hushållet amortera ytterligare <nobr>1 %</nobr> per år om bolånen är mer än <nobr>4,5 gånger</nobr> 
                hushållets totala bruttoårsinkomst (skuldkvot). Det gäller även om belåningsgraden är under <nobr>50 procent</nobr> av bostadens 
                värde.</p>
            `,
            "maintenance-heading": "Driftkostnad",
            maintenance: `
                <p>Driftkostnaden är den summan hushållet betalar för el, internet, sophämtning, renhållning, vatten, försäkringar etc.</p>
            `,
            "condominium-fee-heading": "Bostadsrättsavgift",
            "condominium-fee": `
                <p>Du som bor i en bostadsrätt i Sverige betalar en månadsavgift till din bostadsrättsförening. Föreningen använder 
                pengarna till underhåll, skötsel och reparationer av fastigheten. Vad som ingår i bostadsrättsavgiften skiljer sig mellan olika 
                föreningar.</p>`,
            "property-tax-heading": "Fastighetsavgift",
            "property-tax-move-mortgage": `
                <p>Du som äger en fastighet i Sverige ska betala en fastighetsavgift till kommunen. Fastighetsavgiften ligger på <nobr>0,75 %</nobr> 
                av bostadens taxeringsvärde. Taxeringsvärdet uppskattas i kalkylen uppgå till <nobr>75 %</nobr> av det marknadsvärdet som 
                angivits.</p>
                <p>De flesta bostäder i tätorter når maxtaket för fastighetsavgift som ligger på <nobr>{{maxPropertyTax}} kr</nobr> per år.</p>
            `,
            "property-tax-new-mortgage": `
                <p>Du som äger en fastighet i Sverige ska betala en fastighetsavgift till kommunen. Fastighetsavgiften ligger på <nobr>0,75 %</nobr> 
                av bostadens taxeringsvärde. Taxeringsvärdet uppskattas i denna kalkyl ligga på <nobr>75 %</nobr> av bostadens värde.</p>
                <p>De flesta bostäder i tätorter når maxtaket för fastighetsavgift som ligger på {{maxPropertyTax}} kr per år. I kalkylens beräkning 
                av fastighetsavgift räknar vi med den maximala kostnaden då marknadsvärdet är okänt.</p>
            `,
        },
        [TranslationNamespaceEnum.SUFFIXES]: {
            sek: "kr",
            sekPerMonth: " kr / mån",
            squareMeters: "kvm",
        },
    },
};

import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";

import type { ITranslation } from "./translations.types";
import { LanguageKeyEnum } from "./translations.types";
import { defaultTranslations } from "./default-translations";

const defaultLngKey = LanguageKeyEnum.SV;
const customLngKey = LanguageKeyEnum.CUSTOM;

function extractNamespaces(translations: ITranslation) {
    const customResources: ResourceLanguage = {};
    Object.keys(translations).forEach((key) => {
        const res = key.split(":");
        if (res == null || res.length !== 2) {
            throw new Error(`Custom language key "${key}" has incorrect format`);
        }

        const [namespace, tk] = res;
        if (!customResources[namespace]) {
            customResources[namespace] = {};
        }

        customResources[namespace][tk] = translations[key];
    });
    return customResources;
}

function overload(customTranslations: ITranslation) {
    if (!customTranslations) {
        return;
    }

    const customResources = extractNamespaces(customTranslations);
    Object.keys(customResources).forEach((customResource) => {
        i18n.addResources(customLngKey, customResource, customResources[customResource]);
    });

    i18n.changeLanguage(customLngKey);
}

interface IProps {
    languageKey?: LanguageKeyEnum;
    customTranslations?: ITranslation;
    debugMode?: boolean;
}

export const initTranslations = ({ languageKey = defaultLngKey, customTranslations = {}, debugMode }: IProps) => {
    i18n.use(initReactI18next).init({
        resources: {
            ...defaultTranslations,
        },
        fallbackLng: defaultLngKey,
        lng: debugMode ? "cimode" : languageKey,
        saveMissing: true,
        debug: debugMode,
        appendNamespaceToCIMode: debugMode,
        missingKeyHandler: (lng, ns, key) => {
            if (lng.includes(languageKey) && process.env.NODE_ENV !== "production") {
                throw new Error(`Language: "${languageKey}" is missing translation for key "${ns}:${key}"`);
            }
        },
    });

    if (debugMode) {
        return;
    }

    overload(customTranslations);
};

import { css, Theme } from "@emotion/react";
import type { ICSSProperties, IGridProps, ILabelStyleOverrides } from "@econans/ui-components";

const inheritLabelStyles = (theme: Theme, labelStyles?: ILabelStyleOverrides | ILabelStyleOverrides["desktop"]) =>
    Object.entries(labelStyles || {}).reduce((acc, [key, value]) => {
        if (key === "fontFamily") {
            return {
                ...acc,
                fontFamily: theme.typography.font[value],
            };
        }
        if (key === "fontSize") {
            return {
                ...acc,
                fontSize: theme.typography.size[value],
            };
        }
        if (key === "desktop") {
            return acc;
        }
        return {
            ...acc,
            [key]: value,
        };
    }, {});

const form = (theme: Theme) => css`
    all: revert;
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: rows;
    gap: ${theme.spacing(10)};
    margin: 0;
    padding: 0;
`;

const formFieldset = () => css`
    all: revert;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
`;

const formFieldsetLegend = (theme: Theme) => css`
    all: revert;
    box-sizing: border-box;
    margin: 0;
    padding: ${theme.spacing(0, 0, 3)};
    ${css({ ...inheritLabelStyles(theme, theme.component?.Label) })}
    ${theme.mq({
        theme,
        min: "DESKTOP",
        css: css({ ...inheritLabelStyles(theme, theme.component?.Label?.desktop) }).styles,
    })}
`;

const disguisedRadioButtonWithIcon = (theme: Theme) => css`
    span { 
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: ${theme.spacing(4)};
    };
`;

const formChildNumberInput = () => css`
    width: 160px;

    input {
        text-align: center;
    }
`;

const formAddButton = () => css`
    width: 100%;
    margin-top: 48px;
    justify-content: center;
    gap: 8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
`;

const grid = (theme: Theme) => css`
    grid-auto-flow: column;
    gap: ${theme.spacing(4)};
    ${theme.widget?.Form?.additionalHousings?.radioButtons?.narrowGrid ? `justify-content: start;` : ""}
`;

export const styles = { grid, form, formFieldset, formFieldsetLegend, disguisedRadioButtonWithIcon, formChildNumberInput, formAddButton };

interface IProspectTypeStyles {
    icon?: {
        component?: React.ComponentType;
        placement?: "beforeContent" | "afterContent";
    };
}

export interface IFormStyleOverrides {
    prospectType?: Pick<IGridProps, "columns"> & {
        house?: IProspectTypeStyles;
        condominium?: IProspectTypeStyles;
        cottage?: IProspectTypeStyles;
        columns?: {
            mobile: number;
            desktop: number;
        };
        gap?: ICSSProperties["gap"];
    };
    additionalHousings?: {
        radioButtons?: {
            narrowGrid: boolean;
        };
        buttons?: {
            remove?: {
                icon: React.ComponentType;
            };
            add?: {
                icon: React.ComponentType;
            };
            spacer?: {
                color?: ICSSProperties["color"];
            };
        };
    };
    additionalLoans?: {
        radioButtons?: {
            narrowGrid: boolean;
        };
        buttons?: {
            remove?: {
                icon: React.ComponentType;
            };
            add?: {
                icon: React.ComponentType;
            };
            spacer?: Pick<ICSSProperties, "color">;
        };
    };
}

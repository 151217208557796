import React, { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { css, useTheme } from "@emotion/react";
import {
    Button,
    DescriptionList,
    Grid,
    Heading,
    NonBreakingSpace,
    Paragraph,
    RenderComponentInstance,
    TooltipModal,
    TransBold,
} from "@econans/ui-components";

import type { IFormValues, IResultValues } from "@Components";
import { CustomerScenario, ProspectType, TranslationNamespaceEnum, useConfiguration, useEvent } from "@Utils";
import { TransLink } from "../TransLink";
import { Section } from "../sharedStyles";
import { ResultDescriptionDetail, ResultDescriptionList, ResultDescriptionTerm, styles } from "./Result.styles";

interface IResultProps {
    formValues: IFormValues;
    resultValues: IResultValues;
}

export const Result: React.FC<IResultProps> = ({ formValues, resultValues }) => {
    const configuration = useConfiguration();
    const { trackEvent } = useEvent(configuration);
    const { minMortgageAllowed, maxPropertyTax } = configuration.calculationParameters;
    const {
        customerScenario,
        maxHousingPrice,
        downPayment,
        maxLoanAmount,
        monthlyCostAfterDeduction,
        monthlyCostBeforeDeduction,
        nominalInterestExpense,
        listInterestPeriod,
        nominalInterestRate,
        effectiveInterestRate,
        amortizationExpense,
        amortizationRate,
        maintenanceExpense,
        condominiumFee,
        propertyTax,
    } = resultValues;

    const { t } = useTranslation([TranslationNamespaceEnum.RESULT, TranslationNamespaceEnum.INFO, TranslationNamespaceEnum.SUFFIXES]);
    const theme = useTheme();

    const explanationTableRef = useRef(null);
    const [resultsPresented, setResultsPresented] = useState(false);

    const isNewHousingScenario = customerScenario === CustomerScenario.BUY_NEW_HOUSING;
    const highlightedAmount = isNewHousingScenario ? maxHousingPrice : maxLoanAmount;

    const hasRequiredInput = Object.keys(resultValues).length > 1;
    let aboveMinMortgageThreshold;

    if (maxLoanAmount && minMortgageAllowed) {
        // In order to read the value as a number, we need to revert the formatting (nbsp and delimiter) done in App.tsx.
        const deformattedMaxLoanAmount = maxLoanAmount.replace(/\u00A0/g, "").replace(",", ".");

        aboveMinMortgageThreshold = Number(deformattedMaxLoanAmount) > Number(minMortgageAllowed);
    }

    // Track the first time valid results are presented to the user
    if (!resultsPresented && aboveMinMortgageThreshold) {
        trackEvent({
            category: "widget",
            action: "loaded",
            label: "First valid results presented",
        });
        setResultsPresented(true);
    }

    const handleCTAClick = (type: "primary" | "secondary", onCTAClick?: () => void) => {
        trackEvent({
            category: "user",
            action: "click",
            label: `Clicked ${type} CTA button`,
        });

        if (onCTAClick) {
            onCTAClick();
        }
    };

    const handleInfoClick = (instance: "amortization" | "interest" | "condominium-fee" | "operating-cost" | "property-tax") => {
        trackEvent({
            category: "user",
            action: "click",
            label: `Clicked info ${instance}`,
        });
    };

    const getResultOutput = (fieldValue?: number | string) => {
        if (!hasRequiredInput) {
            return "-";
        }
        if (minMortgageAllowed && !aboveMinMortgageThreshold) {
            return "0";
        }

        return fieldValue;
    };

    return (
        <Grid css={styles.result}>
            <Section>
                <Heading level="3">{t(isNewHousingScenario ? "prelimiary-buy-label" : "prelimiary-loan-label")}</Heading>

                <TransBold data-testid="top-result" css={styles.loanAmount}>
                    {`${getResultOutput(highlightedAmount)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sek`)}`}
                </TransBold>
                {!hasRequiredInput ||
                    (minMortgageAllowed && !aboveMinMortgageThreshold && (
                        <Paragraph data-testid="top-result-disclaimer">
                            <Trans
                                t={t}
                                i18nKey={`${TranslationNamespaceEnum.DISCLAIMER}:below-min-mortgage-threshold`}
                                tOptions={{
                                    interpolation: { escapeValue: false },
                                }}
                                components={{ nobr: <NonBreakingSpace /> }}
                                values={{ minMortgageAllowed }}
                            />
                        </Paragraph>
                    ))}

                <DescriptionList
                    css={styles.housingValue}
                    items={
                        isNewHousingScenario
                            ? [
                                  {
                                      term: t("downPayment"),
                                      detail: `${getResultOutput(downPayment)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sek`)}`,
                                  },
                                  {
                                      term: t("max-loan"),
                                      detail: `${getResultOutput(maxLoanAmount)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sek`)}`,
                                  },
                              ]
                            : [
                                  {
                                      term: t("housing-value"),
                                      detail: `${getResultOutput(maxHousingPrice)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sek`)}`,
                                  },
                              ]
                    }
                />
                <Paragraph>{t(`${TranslationNamespaceEnum.DISCLAIMER}:max-loan-explanation`)}</Paragraph>

                {configuration.onPrimaryCTAClick && Boolean(!hasRequiredInput || (maxLoanAmount && aboveMinMortgageThreshold)) && (
                    <Button
                        css={styles.primaryCtaButton}
                        icon={
                            theme.widget?.Result?.cta?.primary?.icon && (
                                <RenderComponentInstance instance={theme.widget?.Result?.cta?.primary?.icon} />
                            )
                        }
                        variant="PRIMARY"
                        data-testid="primary-cta-button"
                        onClick={() => handleCTAClick("primary", configuration.onPrimaryCTAClick)}
                    >
                        {t("primary-cta-button")}
                    </Button>
                )}

                {configuration.onSecondaryCTAClick && Boolean(!hasRequiredInput || (maxLoanAmount && aboveMinMortgageThreshold)) && (
                    <Button
                        css={styles.secondaryCtaButton}
                        icon={
                            theme.widget?.Result?.cta?.primary?.icon && (
                                <RenderComponentInstance instance={theme.widget?.Result?.cta?.primary?.icon} />
                            )
                        }
                        variant="SECONDARY"
                        data-testid="secondary-cta-button"
                        onClick={() => handleCTAClick("secondary", configuration.onSecondaryCTAClick)}
                    >
                        {t("secondary-cta-button")}
                    </Button>
                )}
            </Section>

            <Section>
                <Heading level="3">{t("monthly-cost-after-tax-deduction")}</Heading>

                <TransBold css={styles.monthlyCostAfterTaxDeduction}>{`${getResultOutput(monthlyCostAfterDeduction)} ${t(
                    `${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`,
                )}`}</TransBold>

                {Boolean(!hasRequiredInput || (maxLoanAmount && aboveMinMortgageThreshold)) && (
                    <DescriptionList
                        items={[
                            {
                                term: t("monthly-cost-before-tax-deduction"),
                                detail: `${`${getResultOutput(monthlyCostBeforeDeduction)} ${t(
                                    `${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`,
                                )}`}`,
                            },
                        ]}
                        css={css`margin-bottom: ${theme.spacing(8)}`}
                    />
                )}

                <Heading level="3">{t("calculation-explanation-label")}</Heading>

                <ResultDescriptionList css={css`margin-bottom: ${theme.spacing(5)};`} ref={explanationTableRef}>
                    <ResultDescriptionTerm css={css`font-family: ${theme.typography.font.secondary};`}>
                        {t("nominal-interest", { listInterestPeriod: listInterestPeriod ? ` (${listInterestPeriod})` : "" })}

                        <TooltipModal
                            id="econans-borrowing-space-interest-info"
                            css={styles.toolTipModal}
                            boundryElementRef={explanationTableRef}
                            onToggle={() => handleInfoClick("interest")}
                            toggleButtonTitle={t(`${TranslationNamespaceEnum.GENERIC}:tooltip-title`)}
                        >
                            <React.Fragment>
                                <Heading level="4">{t(`${TranslationNamespaceEnum.INFO}:interest-heading`)}</Heading>
                                <Trans
                                    t={t}
                                    i18nKey={`${TranslationNamespaceEnum.INFO}:interest`}
                                    tOptions={{
                                        interpolation: { escapeValue: false },
                                    }}
                                    components={{
                                        nobr: <NonBreakingSpace />,
                                        p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                                        url: <TransLink />,
                                    }}
                                />
                            </React.Fragment>
                        </TooltipModal>
                    </ResultDescriptionTerm>
                    <ResultDescriptionDetail
                        css={css`
                                font-family: ${theme.typography.font.secondary};
                            `}
                    >
                        {`${getResultOutput(nominalInterestRate)} %`}
                    </ResultDescriptionDetail>
                    <ResultDescriptionDetail
                        css={css`
                                font-family: ${theme.typography.font.secondary};
                                white-space: normal;
                            `}
                    >
                        {`${getResultOutput(nominalInterestExpense)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}`}
                    </ResultDescriptionDetail>

                    <ResultDescriptionTerm bottomSpacing="sm">{t("effective-interest")}</ResultDescriptionTerm>
                    <ResultDescriptionDetail>{`${getResultOutput(effectiveInterestRate)} %`}</ResultDescriptionDetail>

                    <ResultDescriptionTerm bottomSpacing="sm">
                        {t("amortization")}

                        <TooltipModal
                            id="econans-borrowing-space-amortization-info"
                            css={styles.toolTipModal}
                            boundryElementRef={explanationTableRef}
                            onToggle={() => handleInfoClick("amortization")}
                            toggleButtonTitle={t(`${TranslationNamespaceEnum.GENERIC}:tooltip-title`)}
                        >
                            <React.Fragment>
                                <Heading level="4">{t(`${TranslationNamespaceEnum.INFO}:amortization-heading`)}</Heading>
                                <Trans
                                    t={t}
                                    i18nKey={`${TranslationNamespaceEnum.INFO}:amortization`}
                                    tOptions={{
                                        interpolation: { escapeValue: false },
                                    }}
                                    components={{
                                        nobr: <NonBreakingSpace />,
                                        p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                                        url: <TransLink />,
                                    }}
                                />
                            </React.Fragment>
                        </TooltipModal>
                    </ResultDescriptionTerm>
                    <ResultDescriptionDetail>{`${getResultOutput(amortizationRate)} %`}</ResultDescriptionDetail>
                    <ResultDescriptionDetail css={css`white-space: normal;`}>
                        {`${getResultOutput(amortizationExpense)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}`}
                    </ResultDescriptionDetail>

                    <ResultDescriptionTerm bottomSpacing="sm">
                        {t("operating-cost")}

                        <TooltipModal
                            id="econans-borrowing-space-maintenance-info"
                            css={styles.toolTipModal}
                            boundryElementRef={explanationTableRef}
                            onToggle={() => handleInfoClick("operating-cost")}
                            toggleButtonTitle={t(`${TranslationNamespaceEnum.GENERIC}:tooltip-title`)}
                        >
                            <React.Fragment>
                                <Heading level="4">{t(`${TranslationNamespaceEnum.INFO}:maintenance-heading`)}</Heading>
                                <Trans
                                    t={t}
                                    i18nKey={`${TranslationNamespaceEnum.INFO}:maintenance`}
                                    tOptions={{
                                        interpolation: { escapeValue: false },
                                    }}
                                    components={{
                                        nobr: <NonBreakingSpace />,
                                        p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                                    }}
                                />
                            </React.Fragment>
                        </TooltipModal>
                    </ResultDescriptionTerm>
                    <ResultDescriptionDetail
                        css={css`
                                grid-column: 3 / 4;
                                white-space: nowrap;
                            `}
                    >
                        {`${getResultOutput(maintenanceExpense)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}`}
                    </ResultDescriptionDetail>

                    {formValues.prospectType === ProspectType.CONDOMINIUM && (
                        <React.Fragment>
                            <ResultDescriptionTerm bottomSpacing="sm">
                                {t(`${TranslationNamespaceEnum.RESULT}:condominium-fee`)}

                                <TooltipModal
                                    id="econans-borrowing-space-condominium-fee"
                                    css={styles.toolTipModal}
                                    boundryElementRef={explanationTableRef}
                                    onToggle={() => handleInfoClick("condominium-fee")}
                                    toggleButtonTitle={t(`${TranslationNamespaceEnum.GENERIC}:tooltip-title`)}
                                >
                                    <React.Fragment>
                                        <Heading level="4">{t(`${TranslationNamespaceEnum.INFO}:condominium-fee-heading`)}</Heading>
                                        <Trans
                                            t={t}
                                            i18nKey={`${TranslationNamespaceEnum.INFO}:condominium-fee`}
                                            tOptions={{
                                                interpolation: { escapeValue: false },
                                            }}
                                            components={{
                                                nobr: <NonBreakingSpace />,
                                                p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                                            }}
                                        />
                                    </React.Fragment>
                                </TooltipModal>
                            </ResultDescriptionTerm>
                            <ResultDescriptionDetail
                                css={css`
                                grid-column: 3 / 4;
                                white-space: nowrap;
                            `}
                            >
                                {`${getResultOutput(condominiumFee)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}`}
                            </ResultDescriptionDetail>
                        </React.Fragment>
                    )}

                    {formValues.prospectType !== ProspectType.CONDOMINIUM && (
                        <React.Fragment>
                            <ResultDescriptionTerm bottomSpacing="sm">
                                {t("property-tax")}

                                <TooltipModal
                                    id="econans-borrowing-space-property-tax-info"
                                    css={styles.toolTipModal}
                                    boundryElementRef={explanationTableRef}
                                    onToggle={() => handleInfoClick("property-tax")}
                                    toggleButtonTitle={t(`${TranslationNamespaceEnum.GENERIC}:tooltip-title`)}
                                >
                                    <React.Fragment>
                                        <Heading level="4">{t(`${TranslationNamespaceEnum.INFO}:property-tax-heading`)}</Heading>
                                        <Trans
                                            t={t}
                                            i18nKey={`${TranslationNamespaceEnum.INFO}:${
                                                isNewHousingScenario ? "property-tax-new-mortgage" : "property-tax-move-mortgage"
                                            }`}
                                            tOptions={{
                                                interpolation: { escapeValue: false },
                                            }}
                                            components={{
                                                nobr: <NonBreakingSpace />,
                                                p: <Paragraph noBottomMarginOnLast css={css`white-space: normal;`} />,
                                            }}
                                            values={{ maxPropertyTax }}
                                        />
                                    </React.Fragment>
                                </TooltipModal>
                            </ResultDescriptionTerm>
                            <ResultDescriptionDetail css={css`grid-column: 3 / 4; white-space: normal;`}>
                                {`${getResultOutput(propertyTax)} ${t(`${TranslationNamespaceEnum.SUFFIXES}:sekPerMonth`)}`}
                            </ResultDescriptionDetail>
                        </React.Fragment>
                    )}
                </ResultDescriptionList>

                <Paragraph>{t(`${TranslationNamespaceEnum.DISCLAIMER}:calculation-explanation`)}</Paragraph>
            </Section>
        </Grid>
    );
};

import { ICalculationParameters } from "@Clients/client.types";
import { MarketSE } from "@econans/calculations";

export function mapLocalizationParameters(
    defaultLocalizationParameters: MarketSE.IForecastLocalizationParameters,
    calculationParameters: ICalculationParameters,
): MarketSE.IForecastLocalizationParameters {
    let adultsExpense;
    if (calculationParameters.adultsHouseholdExpense) {
        const { oneAdult, twoAdults } = calculationParameters.adultsHouseholdExpense;
        adultsExpense = [oneAdult, twoAdults];
    }

    const updatedLocalizationParameters: MarketSE.IForecastLocalizationParameters = {
        mortgage: defaultLocalizationParameters.mortgage,
        household: {
            estimatedSalaryIncreaseFactor:
                calculationParameters.estimatedSalaryIncreaseFactor ?? defaultLocalizationParameters.household.estimatedSalaryIncreaseFactor,
            retirementAge: calculationParameters.retirementAge ?? defaultLocalizationParameters.household.retirementAge,
            costOfAdults: adultsExpense ?? defaultLocalizationParameters.household.costOfAdults,
            costOfChild: calculationParameters.childHouseholdExpense ?? defaultLocalizationParameters.household.costOfChild,
            maxChildAge: calculationParameters.maxChildAge ?? defaultLocalizationParameters.household.maxChildAge,
        },
    };

    return updatedLocalizationParameters;
}
